import Botones from './components/Botones/Botones'
import Historial from './components/Historial/Historial';
import Historia from './components/Historia/Historia';
import Disenio from './components/Disenio/Disenio'

function App() {
  return (
    <div className="layout">
      <Disenio/>
    </div>
  );
}

export default App;
